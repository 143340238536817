import React from 'react';

const AgentIcon = ({active}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g fill="none" fillRule="evenodd" opacity={`${active ? "1" : ".8"}`}>
            <g fill="#31326F" fillRule="nonzero">
                <g>
                    <path d="M34.833 0H15.2c-1.748.002-3.165 1.419-3.167 3.167v2.56c.21-.013.418-.027.634-.027.215 0 .424.014.633.027v-2.56c0-1.05.85-1.9 1.9-1.9h19.633c1.05 0 1.9.85 1.9 1.9v5.7c0 1.049-.85 1.9-1.9 1.9h-5.066c-.134 0-.264.042-.373.12l-5.557 4.042.844-3.375c.047-.19.005-.39-.115-.544-.12-.153-.304-.243-.5-.243h-3.662c.147.412.262.836.342 1.266h2.51l-1.07 4.28c-.065.258.039.53.26.679.22.149.51.144.726-.013l6.8-4.946h4.861c1.748-.002 3.165-1.418 3.167-3.166v-5.7C37.998 1.419 36.581.002 34.833 0z" transform="translate(-22 -335) translate(22 335)"/>
                    <path d="M21.749 26.126c-1.432-.406-2.804-.996-4.083-1.755l-1.2-1.2v-1.496c2.01-2.142 3.141-4.962 3.167-7.9 0-4.136-2.734-6.808-6.966-6.808-4.233 0-6.967 2.672-6.967 6.808.026 2.938 1.157 5.758 3.167 7.9v1.496l-1.2 1.2c-1.278.76-2.65 1.35-4.082 1.755C1.475 26.79 0 29.092 0 31.722v5.645c0 .35.284.633.633.633H24.7c.35 0 .633-.284.633-.633v-5.645c0-2.63-1.474-4.932-3.584-5.596zM9.599 24.23l2.047 1.228-1.386 1.386-1.228-2.047.566-.567zm2.99 4.902h.156l1.014 7.6h-2.184l1.013-7.6zm.282-1.266h-.409l-.41-1.024.615-.614.614.614-.41 1.024zm.817-2.407l2.047-1.229.57.57-1.232 2.044-1.385-1.385zM12.667 8.233c3.57 0 5.7 2.072 5.7 5.542 0 .049-.005.1-.007.148-1.506-.28-2.706-1.422-3.06-2.913-.046-.28-.274-.495-.556-.526-.282-.03-.55.13-.656.395-.602 1.503-4.48 2.9-7.093 3.524-.015-.212-.026-.422-.026-.63-.002-3.468 2.128-5.54 5.698-5.54zm-5.501 7.432c1.372-.323 5.462-1.405 7.318-3.211.768 1.445 2.16 2.453 3.772 2.733-.518 3.437-2.816 6.98-5.59 6.98-2.644 0-4.851-3.222-5.5-6.502zm5.5 7.768c.9-.014 1.776-.277 2.534-.76v.401l-2.533 1.52-2.534-1.52v-.4c.758.482 1.635.745 2.534.76zm-11.4 8.289c0-2.083 1.11-3.888 2.7-4.389 1.42-.417 2.788-.994 4.077-1.72l1.548 2.58c.1.167.272.278.466.302.193.024.387-.043.525-.18l.497-.498.305.76-1.088 8.156H5.7v-3.8H4.433v3.8H1.267v-5.011zm22.8 5.011H20.9v-3.8h-1.267v3.8h-4.596L13.95 28.58l.305-.76.497.497c.138.138.332.204.525.18.194-.023.365-.135.466-.302l1.547-2.582c1.29.726 2.657 1.303 4.077 1.72 1.59.501 2.7 2.307 2.7 4.389v5.011z" transform="translate(-22 -335) translate(22 335)"/>
                </g>
            </g>
        </g>
    </svg>
);

export default AgentIcon;
