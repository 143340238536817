import React from 'react';

const DashboardIcon = ({active}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39" viewBox="0 0 40 39">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero" stroke="#31326F">
                <g>
                    <path d="M15.136 0H2.864C1.284 0 0 1.276 0 2.844v7.312C0 11.724 1.284 13 2.864 13h12.272C16.716 13 18 11.724 18 10.156V2.844C18 1.276 16.716 0 15.136 0zM15.136 16H2.864C1.284 16 0 17.177 0 18.625v15.75C0 35.823 1.284 37 2.864 37h12.272C16.716 37 18 35.823 18 34.375v-15.75C18 17.177 16.716 16 15.136 16zM35.136 24H22.864C21.284 24 20 25.276 20 26.844v7.312C20 35.724 21.284 37 22.864 37h12.272C36.716 37 38 35.724 38 34.156v-7.312C38 25.276 36.716 24 35.136 24h0zM35.136 0H22.864C21.284 0 20 1.177 20 2.625v15.75C20 19.823 21.284 21 22.864 21h12.272C36.716 21 38 19.823 38 18.375V2.625C38 1.177 36.716 0 35.136 0h0z" transform="translate(-21 -122) translate(22 123)"/>
                </g>
            </g>
        </g>
    </svg>
);

export default DashboardIcon;