import React from 'react';

const PartyIcon = ({active}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
    <g fill="none" fillRule="evenodd">
        <g fill="#31326F" fillRule="nonzero">
            <g>
                <path d="M22.087 7.296l-3.479-.483-1.515-2.934-1.215.008-1.511 2.926-3.47.482-.23 1.147 2.39 2.226-.588 3.273 1.042.604 2.977-1.495 2.973 1.494 1.046-.6-.588-3.276 2.384-2.221-.216-1.15zm-3.933 4.498l-1.666-.837-1.667.837.318-1.773-1.348-1.256 1.863-.259.834-1.613.833 1.613 1.863.26-1.348 1.255.318 1.773z" transform="translate(-21 -547) translate(22 548)" opacity={`${active ? "1" : ".56"}`}/>
                <path stroke="#31326F" opacity={`${active ? "1" : ".56"}`} d="M32 0H0v18.216h10.946c-.368.748-.575 1.589-.575 2.477 0 1.74.792 3.297 2.035 4.33-.783.269-1.511.628-2.167 1.077-.206.142-.404.29-.592.447-.334-.268-.707-.495-1.111-.68.558-.649.897-1.491.897-2.412 0-2.04-1.66-3.7-3.699-3.7-2.04 0-3.698 1.66-3.698 3.7 0 .92.338 1.763.896 2.411-.433.2-.832.446-1.185.739C.624 27.537.005 28.892.005 30.42V32h31.99v-1.58c0-1.528-.619-2.883-1.742-3.815-.353-.293-.752-.54-1.185-.739.558-.648.896-1.49.896-2.41 0-2.04-1.659-3.7-3.698-3.7-2.04 0-3.699 1.66-3.699 3.7 0 .92.339 1.762.897 2.41-.404.186-.777.413-1.111.68-.188-.156-.386-.304-.592-.446-.656-.449-1.384-.808-2.167-1.077 1.243-1.034 2.035-2.59 2.035-4.33 0-.888-.207-1.729-.575-2.477H32V0zM5.734 21.63c1.006 0 1.824.82 1.824 1.825 0 1.007-.818 1.825-1.824 1.825-1.006 0-1.824-.818-1.824-1.825 0-1.006.818-1.824 1.824-1.824zm-3.843 8.495c.162-2.048 2.01-2.97 3.843-2.97.947 0 1.898.247 2.618.768-.48.663-.846 1.401-1.087 2.202H1.89zm28.218 0h-5.374c-.24-.8-.606-1.539-1.087-2.201.72-.521 1.67-.769 2.618-.769 1.833 0 3.681.922 3.843 2.97h0zm-3.843-8.494c1.006 0 1.824.818 1.824 1.824 0 1.007-.818 1.825-1.824 1.825-1.006 0-1.824-.818-1.824-1.825 0-1.006.818-1.824 1.824-1.824zm-3.522 8.494H9.256c.989-2.343 3.512-3.801 6.744-3.801 3.232 0 5.755 1.458 6.744 3.801zM16 24.45c-2.07 0-3.754-1.685-3.754-3.756 0-2.07 1.684-3.755 3.754-3.755 2.07 0 3.755 1.684 3.755 3.755 0 2.071-1.685 3.756-3.755 3.756zm3.568-8.108c-.972-.798-2.215-1.278-3.568-1.278-1.353 0-2.596.48-3.568 1.278H1.874v-6.32h6.621V8.148h-6.62V1.875h28.25v6.272h-6.593v1.875h6.594v6.32H19.568z" transform="translate(-21 -547) translate(22 548)"/>
            </g>
        </g>
    </g>
</svg>
)

export default PartyIcon;