import React from 'react';

const ResultIcon = ({active}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="44" viewBox="0 0 40 44">
    <g fill="none" fillRule="evenodd">
        <g fill="#31326F" fillRule="nonzero">
            <g transform="translate(-21 -189) translate(22 190)">
                <path stroke="#31326F" opacity={`${active ? "1" : ".56"}`} d="M34.689 42H3.31C1.484 41.998.002 40.752 0 39.215V7.786C.002 6.248 1.483 5.002 3.311 5h6.217c.396 0 .716.27.716.602 0 .333-.32.603-.716.603H3.311c-1.037 0-1.878.708-1.879 1.581v31.429c.002.872.842 1.58 1.88 1.58h31.377c1.037 0 1.877-.708 1.879-1.58V7.786c-.001-.873-.842-1.58-1.88-1.581h-6.216c-.396 0-.716-.27-.716-.603 0-.332.32-.602.716-.602h6.217c1.828.002 3.309 1.248 3.311 2.786v31.429c-.002 1.537-1.484 2.783-3.311 2.785z"/>
                <path stroke="#31326F" opacity={`${active ? "1" : ".56"}`} d="M33.284 39H4.716C4.321 39 4 38.731 4 38.4V9.6c0-.331.32-.6.716-.6H9.52c.396 0 .716.269.716.6 0 .332-.32.6-.716.6H5.432v27.6h27.136V10.2H28.48c-.396 0-.716-.268-.716-.6 0-.331.32-.6.716-.6h4.804c.395 0 .716.269.716.6v28.8c0 .331-.32.6-.716.6z"/>
                <path stroke="#31326F" opacity={`${active ? "1" : ".56"}`} d="M27.247 11H10.753C9.785 11 9 10.301 9 9.44V6.348c.002-1.442 1.315-2.611 2.936-2.612h3.443l.538-1.623C16.287.863 17.561-.005 19.013 0c1.452.005 2.717.883 3.076 2.135l.532 1.6h3.443c1.62.001 2.934 1.17 2.936 2.612V9.44c0 .861-.785 1.56-1.753 1.56h0zM11.936 4.984c-.846 0-1.532.61-1.533 1.364V9.44c0 .173.157.312.35.312h16.494c.194 0 .35-.14.35-.312V6.348c0-.753-.686-1.363-1.532-1.364h-3.967c-.311 0-.585-.182-.673-.448l-.687-2.069c-.191-.71-.903-1.213-1.724-1.219-.821-.005-1.541.488-1.745 1.196l-.693 2.092c-.088.266-.362.448-.673.448h-3.967z"/>
                <circle cx="19" cy="4" r="1" stroke="#31326F" opacity={`${active ? "1" : ".56"}`}/>
                <path stroke="#31326F" opacity={`${active ? "1" : ".56"}`} d="M19.5 5c-.828 0-1.5-.672-1.5-1.5S18.672 2 19.5 2s1.5.672 1.5 1.5c-.003.827-.673 1.497-1.5 1.5zm0-2.257c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75c-.002-.413-.337-.748-.75-.75h0zM28.283 17H9.717C9.321 17 9 16.776 9 16.5s.321-.5.717-.5h18.566c.396 0 .717.224.717.5s-.321.5-.717.5zM28.283 22H9.717C9.321 22 9 21.776 9 21.5s.321-.5.717-.5h18.566c.396 0 .717.224.717.5s-.321.5-.717.5zM10.683 31c-.263 0-.504-.133-.617-.34-.113-.207-.079-.452.088-.63l2.621-2.8c.153-.17.4-.256.644-.223.245.033.451.178.538.38.086.202.04.429-.12.593l-2.621 2.8c-.13.14-.326.22-.533.22h0zM21.691 31c-.32 0-.599-.18-.672-.433-.074-.253.076-.515.363-.631l4.615-1.876c.22-.09.484-.078.69.032.207.11.326.302.312.503-.014.2-.16.38-.38.47l-4.615 1.876c-.097.04-.204.06-.313.059h0zM19.354 31c-.177 0-.346-.07-.468-.193l-2.707-2.745c-.247-.25-.237-.648.02-.888.26-.24.669-.23.916.02l2.706 2.745c.179.181.229.449.127.68-.102.23-.335.38-.594.381h0z"/>
                <path opacity={`${active ? "1" : ".56"}`} d="M9.5 34c-1.011 0-1.923-.487-2.31-1.235-.387-.747-.173-1.607.542-2.18.715-.571 1.79-.742 2.725-.433C11.39 30.462 12 31.192 12 32c-.002 1.104-1.12 1.999-2.5 2zm0-2.83c-.573 0-1.038.372-1.038.83 0 .458.465.83 1.038.83s1.038-.372 1.038-.83c-.001-.458-.465-.83-1.038-.83zM14.5 29c-1.381 0-2.5-.896-2.5-2 0-1.105 1.12-2 2.5-2 1.381 0 2.5.896 2.5 2-.002 1.105-1.12 2-2.5 2zm0-2.83c-.574 0-1.038.373-1.038.831 0 .458.465.83 1.038.83s1.038-.372 1.038-.83c0-.459-.466-.83-1.039-.83z"/>
                <path stroke="#31326F" opacity={`${active ? "1" : ".56"}`} d="M20.5 33c-1.011 0-1.923-.488-2.31-1.235-.387-.748-.172-1.608.544-2.18.715-.572 1.791-.742 2.725-.432.934.31 1.542 1.04 1.541 1.85-.004 1.103-1.121 1.996-2.5 1.997h0zm0-2.83c-.573 0-1.037.371-1.038.83 0 .457.463.829 1.036.83.572.001 1.038-.369 1.04-.827 0-.22-.109-.432-.303-.589-.195-.156-.46-.244-.735-.244h0zM27.5 30c-1.38 0-2.5-.895-2.5-2 0-1.104 1.119-2 2.5-2 1.38 0 2.5.895 2.5 2-.001 1.104-1.12 1.999-2.5 2h0zm0-2.83c-.42 0-.798.202-.959.512-.16.31-.071.668.226.905.296.238.743.308 1.13.18.388-.129.64-.432.64-.768 0-.458-.464-.829-1.037-.83h0z"/>
            </g>
        </g>
    </g>
</svg>
);

export default ResultIcon;
